<template>
	<!-- 商户配置 -->
	<div class="admin-siteset-tanant el-content">
        <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 8 }">
            <a-tabs v-model:activeKey="active">
                <a-tab-pane key="1" tab="基本配置">
                    <div class="tips">
                        <p style="color: #E74C3C;">
                            温馨提示：网站备案查询需要去阿里云(
                                https://market.aliyun.com/products/56928004/cmapi013094.html?spm=5176.11065268.1996646101.searchclickresult.6adb72fc5NQXjL#sku=yuncode709400000
                            )购买相关服务。
                        </p>
                    </div>
                    <a-form-item label="是否开启sass">
                        <a-radio-group v-model:value="form.is_open_sass">
                            <a-radio value="1">是</a-radio>
                            <a-radio value="0">否</a-radio>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item label="是否开启应用试用">
                        <a-radio-group v-model:value="form.app_is_open_try">
                            <a-radio value="1">是</a-radio>
                            <a-radio value="0">否</a-radio>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item label="试用天数"><a-input v-model:value="form.app_try_days"></a-input></a-form-item>
                    <a-form-item label="用户域名审核开关">
                        <a-radio-group v-model:value="form.user_domain_audit">
                            <a-radio value="1">是</a-radio>
                            <a-radio value="0">否</a-radio>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item label="网站备案查询开关">
                        <a-radio-group v-model:value="form.yishuju_icp_search_switch">
                            <a-radio value="1">是</a-radio>
                            <a-radio value="0">否</a-radio>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item label="免费版付费引导">
                        <a-radio-group v-model:value="form.saas_free_pay_hint">
                            <a-radio value="1">开启</a-radio>
                            <a-radio value="0">关闭</a-radio>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item label="网站备案查询授权"><a-input v-model:value="form.yishuju_icp_search_auth_code"></a-input></a-form-item>
                    <a-form-item label="PC端域名"><a-input v-model:value="form.site_pc_domain"></a-input></a-form-item>
                    <a-form-item label="APP端域名"><a-input v-model:value="form.site_app_domain"></a-input></a-form-item>
                    <a-form-item>
                        <template #label>
                            <span>APP端演示域名</span>
                            <a-tooltip title="解析格式 *.h5.farmkd.com;* 代表 APPID 用于替换，如果改变则会删除原有的演示域名">
                                <i class="ri-information-line"></i>
                            </a-tooltip>
                        </template>
                        <a-input v-model:value="form.site_sass_domain_app"></a-input>
                    </a-form-item>
                    <a-form-item>
                        <template #label>
                            <span>PC端演示域名</span>
                            <a-tooltip title="解析格式 *.pc.farmkd.com ;* 代表 APPID 用于替换，如果改变则会删除原有的演示域名">
                                <i class="ri-information-line"></i>
                            </a-tooltip>
                        </template>
                        <a-input v-model:value="form.site_sass_domain_pc"></a-input>
                    </a-form-item>
                    <a-form-item label="视频上传大小限制">
                        <a-input v-model:value="form.file_max_size" addon-after="M"></a-input>
                    </a-form-item>
                    <a-form-item :wrapper-col="{ offset: 3 }">
                        <a-button type="primary" @click="submitSave">保存</a-button>
                    </a-form-item>

                </a-tab-pane>
                <a-tab-pane key="3" tab="小程序服务器域名">
                    <a-form-item label="request 合法域名">
                        <a-input v-model:value="form.wx_mini_request_domain"></a-input>
                    </a-form-item>
                    <a-form-item label="uploadFile 合法域名">
                        <a-input v-model:value="form.wx_mini_uploadFile_domain"></a-input>
                    </a-form-item>
                    <a-form-item label="downloadFile 合法域名">
                        <a-input v-model:value="form.wx_mini_downloadFile_domain"></a-input>
                    </a-form-item>
                    <a-form-item :wrapper-col="{ offset: 3 }">
                        <a-button type="primary" @click="submitSave">保存</a-button>
                    </a-form-item>
                </a-tab-pane>
                <a-tab-pane key="4" tab="全局配置">
                    <a-form-item label="腾讯地图key">
                        <a-input v-model:value="form.saas_tencent_map_key"></a-input>
                    </a-form-item>
                    <a-form-item label="天气接口token">
                        <a-input v-model:value="form.saas_weather_token"></a-input>
                    </a-form-item>
                    <a-form-item label="阿里物流APPID">
                        <a-input v-model:value="form.saas_logistcs_ali_appid "></a-input>
                    </a-form-item>
                    <a-form-item :wrapper-col="{ offset: 3 }">
                        <a-button type="primary" @click="submitSave">保存</a-button>
                    </a-form-item>
                </a-tab-pane>
                <a-tab-pane key="5" tab="默认数据">
                    <a-tabs type="card" v-model:activeKey="defaultState.type" @change="getDefaultData(1,999)">
                        <template #tabBarExtraContent>
                            <a-button type="primary" @click="showDefaultEdit(0)">添加数据</a-button>
                        </template>
<!--                        <a-tab-pane key="index" tab="首页">-->
<!--                            <a-table row-key="id" :pagination="false" :data-source="defaultState.list" :columns="[-->
<!--                                {title:'ID',dataIndex:'id'},-->
<!--                                {title:'标题',dataIndex:'name'},-->
<!--                                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},-->
<!--                            ]">-->
<!--                                <template #action="{record}">-->
<!--                                    <kd-button title="编辑"-->
<!--                                               type="primary"-->
<!--                                               icon="ri-edit-circle-line"-->
<!--                                               @click="showDefaultEdit(record)">-->
<!--                                    </kd-button>-->
<!--                                    <kd-button title="删除"-->
<!--                                               type="danger"-->
<!--                                               icon="ri-delete-bin-line"-->
<!--                                               @click="deleteDefault(record.id)">-->
<!--                                    </kd-button>-->
<!--                                </template>-->
<!--                            </a-table>-->
<!--                        </a-tab-pane>-->
                        <a-tab-pane key="goods" tab="商品">
                            <a-table row-key="id" :pagination="false" :data-source="defaultState.list" :columns="[
                                {title:'ID',dataIndex:'id'},
                                {title:'封面',dataIndex:'cover',slots:{customRender:'cover'}},
                                {title:'商品名称',dataIndex:'content.goods_name'},
                                {title:'价格',dataIndex:'content.price'},
                                {title:'原价',dataIndex:'content.old_price'},
                                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                            ]">
                            <template #cover="{record}">
                                <div class="goods-cover">
                                    <a-image :src="record.content.cover" v-if="record.content" ></a-image>
                                </div>
                            </template>
                            <template #action="{record}">
                                <kd-button title="编辑"
                                           type="primary"
                                           icon="ri-edit-circle-line"
                                           @click="showDefaultEdit(record)">
                                </kd-button>
                                <kd-button title="删除"
                                           type="danger"
                                           icon="ri-delete-bin-line"
                                           @click="deleteDefault(record.id)">
                                </kd-button>
                            </template>
                            </a-table>
                        </a-tab-pane>
                        <a-tab-pane key="land" tab="土地">
                            <a-table row-key="id" :pagination="false" :data-source="defaultState.list" :columns="[
                                {title:'ID',dataIndex:'id'},
                                {title:'封面',dataIndex:'cover',slots:{customRender:'cover'}},
                                {title:'土地名称',dataIndex:'content.land_name'},
                                {title:'价格',dataIndex:'content.price'},
                                {title:'简介',dataIndex:'content.land_intro'},
                                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                            ]">
                                <template #cover="{record}">
                                    <div class="goods-cover">
                                        <a-image :src="record.content.cover" v-if="record.content" ></a-image>
                                    </div>
                                </template>
                                <template #action="{record}">
                                    <kd-button title="编辑"
                                               type="primary"
                                               icon="ri-edit-circle-line"
                                               @click="showDefaultEdit(record)">
                                    </kd-button>
                                    <kd-button title="删除"
                                               type="danger"
                                               icon="ri-delete-bin-line"
                                               @click="deleteDefault(record.id)">
                                    </kd-button>
                                </template>
                            </a-table>
                        </a-tab-pane>
                        <a-tab-pane key="adopt" tab="认养">
                            <a-table row-key="id" :pagination="false" :data-source="defaultState.list" :columns="[
                                {title:'ID',dataIndex:'id'},
                                {title:'封面',dataIndex:'cover',slots:{customRender:'cover'}},
                                {title:'认养名称',dataIndex:'content.name'},
                                {title:'价格',dataIndex:'content.price'},
                                {title:'价格说明',dataIndex:'content.price_description'},
                                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                            ]">
                                <template #cover="{record}">
                                    <div class="goods-cover">
                                        <a-image :src="record.content.cover" v-if="record.content" ></a-image>
                                    </div>
                                </template>
                                <template #action="{record}">
                                    <kd-button title="编辑"
                                               type="primary"
                                               icon="ri-edit-circle-line"
                                               @click="showDefaultEdit(record)">
                                    </kd-button>
                                    <kd-button title="删除"
                                               type="danger"
                                               icon="ri-delete-bin-line"
                                               @click="deleteDefault(record.id)">
                                    </kd-button>
                                </template>
                            </a-table>
                        </a-tab-pane>
                    </a-tabs>

                </a-tab-pane>
            </a-tabs>
        </a-form>

        <a-modal title="添加默认数据"
            width="700px"
            v-model:visible="defaultState.show"
            @cancel="defaultState.show = false"
            @ok="saveDefaultData"
        >
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" v-if="defaultState.form">
                <div v-if="defaultState.type == 'index'">
                    <a-form-item label='标题'>
                        <a-input v-model:value="defaultState.form.name"></a-input>
                    </a-form-item>
                    <a-form-item label='简介'>
                        <a-textarea v-model:value="defaultState.form.content" placeholder="json格式数据" :rows="12" />
                    </a-form-item>
                </div>
                <div v-if="defaultState.type == 'goods'">
                    <a-form-item label='商品名称'>
                        <a-input v-model:value="defaultState.form.goods_name"></a-input>
                    </a-form-item>
                    <a-form-item label='图片'>
                        <kd-img-select
                            :mutil="true"
                            :src="defaultState.form.goods_slide"
                            @success="(url)=>{ defaultState.form.goods_slide = url}">
                        </kd-img-select>
                    </a-form-item>
                    <a-form-item label='现价'>
                        <a-input v-model:value="defaultState.form.price"></a-input>
                    </a-form-item>
                    <a-form-item label='原价'>
                        <a-input v-model:value="defaultState.form.old_price"></a-input>
                    </a-form-item>
                    <a-form-item label='简介'>
                        <a-input v-model:value="defaultState.form.goods_remark"></a-input>
                    </a-form-item>
                </div>
                <div v-if="defaultState.type == 'land'">
                    <a-form-item label='土地名称'>
                        <a-input v-model:value="defaultState.form.land_name"></a-input>
                    </a-form-item>
                    <a-form-item label='土地图片'>
                        <kd-img-select
                            :src="defaultState.form.cover"
                            @success="(url)=>{ defaultState.form.cover = url}">
                        </kd-img-select>
                    </a-form-item>
                    <a-form-item label='土地价格'>
                        <a-input v-model:value="defaultState.form.price"></a-input>
                    </a-form-item>
                    <a-form-item label='土地简介'>
                        <a-input v-model:value="defaultState.form.land_intro"></a-input>
                    </a-form-item>
                </div>
                <div v-if="defaultState.type == 'adopt'">
                    <a-form-item label='认养名称'>
                        <a-input v-model:value="defaultState.form.name"></a-input>
                    </a-form-item>
                    <a-form-item label='认养图片'>
                        <kd-img-select
                            :mutil="true"
                            :src="defaultState.form.slide"
                            @success="(url)=>{ defaultState.form.slide = url}">
                        </kd-img-select>
                    </a-form-item>
                    <a-form-item label='认养价格'>
                        <a-input v-model:value="defaultState.form.price"></a-input>
                    </a-form-item>
                    <a-form-item label='认养简介'>
                        <a-input v-model:value="defaultState.form.price_description"></a-input>
                    </a-form-item>
                </div>
            </a-form>
        </a-modal>
	</div>
</template>

<script>
import {reactive, toRaw, toRefs} from 'vue';
import saasSetModel from '@/api/saas/set';
import setModel from '@/api/saas/set';
export default {
	setup(){
		const state = reactive({
            active:'1',
			form: {
				app_is_open_try: '0',
				app_try_days: '',
				yishuju_icp_search_auth_code: '',
				yishuju_icp_search_switch: '0',
				user_domain_audit: '0',
				site_pc_domain: '',
				site_app_domain: '',
				is_open_sass: '0',
				site_sass_domain_app: '',
				site_sass_domain_pc: '',
				file_max_size:"",
                wx_mini_request_domain:"",
                wx_mini_uploadFile_domain:"",
                wx_mini_downloadFile_domain:"",

                saas_tencent_map_key:"",
                saas_weather_token:"",
                saas_logistcs_ali_appid:"",
                saas_free_pay_hint:'0'
			}
		})

		saasSetModel.getSiteSet(Object.keys(state.form),res=>{
			state.form = res
		})

		const submitSave = ()=>saasSetModel.saveSiteSet({keys:state.form})

        let defaultState = useSetDefault()

		return{
			...toRefs(state),
			submitSave,
            ...defaultState
		}
	}
}


//设置默认商品数据
function useSetDefault(){
    let defaultState = reactive({
        show:false,
        type:'goods',
        list:[],
        form:null
    })
    getDefaultData(1,99)

    function getDefaultData(page,limit){
        setModel.getDefaultDataList(page,limit,{class:defaultState.type},res=>defaultState.list = res)
    }

    function showDefaultEdit(data){
        let { type } =  defaultState
        let _d = data.content
        if( type == 'index' ){
            defaultState.form = {
                id:data ? data.id:0,
                name:data ? data.name : "",
                content:data ? JSON.stringify(_d) :""
            }
        }
        if( type == 'goods'){
            defaultState.form = {
                id:data ? data.id:0,
                goods_name:data ? _d.goods_name:'',
                price:data ? _d.price:'',
                old_price:data ? _d.old_price:'',
                goods_remark:data ? _d.goods_remark:'',
                cover:data ? _d.cover:'',
                goods_desc:data ? _d.goods_desc:'',
                goods_slide:data ? _d.goods_slide:[],
            }
        }
        if( type == 'land'){
            defaultState.form = {
                id:data ? data.id:0,
                land_name:data ? _d.land_name :"",
                land_intro:data ? _d.land_intro :"",
                cover:data ? _d.cover :"",
                land_desc:data ? _d.land_desc :"",
                price:data ? _d.price :"",
            }
        }
        if( type == 'adopt'){
            defaultState.form = {
                id:data ? data.id:0,
                name:data? _d.name :"",
                price:data? _d.price :"",
                cover:data? _d.cover :"",
                slide:data? _d.slide :[],
                price_description:data? _d.price_description :"",
            }
        }

        defaultState.show = true
    }

    function saveDefaultData(){
        let data = toRaw(defaultState.form)
        if( defaultState.type == 'goods' ){
            if( data.goods_slide.length > 0 ) data.cover =data.goods_slide[0]
        }

        if( defaultState.type == 'adopt' ){
            if( data.slide.length > 0 ) data.cover =data.slide[0]
        }


        let id = data.id
        if( !id ) delete data.id
        setModel.setDefaultData(defaultState.type,data,data.id,()=>{
            getDefaultData(1,99)
            defaultState.show = false
        })
    }

    function deleteDefault(id){
        setModel.setDefaultData('delete',null,id,()=>{
            getDefaultData(1,99)
        })
    }

    return { defaultState,getDefaultData ,showDefaultEdit,saveDefaultData,deleteDefault }
}

</script>
<style scoped lang="scss">
.admin-siteset-tanant{
    .goods-cover{
        width: 60px;
    }
}
</style>

